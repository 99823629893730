<template>
  <div>
    <v-card-title>
      공지사항

      <v-spacer />

      <v-btn color="orange" outlined class="mr-3" @click="publishNotice(true)">
        발행
      </v-btn>
      <v-btn outlined color="green" class="mr-3" @click="publishNotice(false)">
        보관
      </v-btn>
      <v-btn outlined color="red" class="mr-3" @click="deleteNotice($route.params.uid)">
        삭제
      </v-btn>
      <v-btn outlined color="blue" @click="notification = true">
        발송
      </v-btn>
    </v-card-title>
    <v-card>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>제목</v-list-item-title>
          <v-text-field v-model="input.title" outlined dense hide-details />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>작성날짜</v-list-item-title>
          <v-text-field outlined disabled dense hide-details :value="day(notice.createdAt)" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>썸네일</v-list-item-title>

          <ImageInput v-model="input.image" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>내용</v-list-item-title>
          <MarkdownEditor v-model="input.content" />
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-spacer />

        <v-btn large outlined @click="$router.push('/notice')">취소</v-btn>
        <v-btn color="#7f00ff" large dark depressed @click="updateNotice($route.params.uid)">
          수정하기
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="notification" trasition="dialog-bottom-transition">
      <v-card>
        <v-card-title>공지사항 발송하기</v-card-title>
        <Notification type="notice" :idOfType="notice.id" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, watch, reactive, toRefs } from '@vue/composition-api'
import { day } from '@/helper'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import Notification from '@/components/Notification.vue'
import ImageInput from '@/components/input/Image.vue'

export default defineComponent({
  components: { MarkdownEditor, Notification, ImageInput },

  props: { notice: Object },

  setup(props, { root, emit }) {
    const state = reactive({
      notification: false,
      input: {
        title: '',
        content: '',
        image: null,
      },
    })

    const publishNotice = async (bool) => {
      try {
        if (!confirm(`공지사항을 ${bool ? '발행' : '보관'}합니다.`)) return

        await root.$store.dispatch('notice/updateNotice', {
          noticeId: Number(root.$route.params.uid),
          publishedAt: bool ? new Date() : null,
        })

        alert(`공지사항이 정상적으로 ${bool ? '발행' : '보관'}되었습니다.`)
        emit('refresh')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const updateNotice = async (id) => {
      try {
        if (!confirm('공지사항을 수정합니다.')) return

        let image
        if (typeof state.input.image !== 'string') image = state.input.image

        await root.$store.dispatch('notice/updateNotice', {
          ...state.input,
          noticeId: Number(id),
          image,
        })

        alert('공지사항이 정상적으로 수정되었습니다.')
        emit('refresh')
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const deleteNotice = async (id) => {
      try {
        if (!confirm('공지사항을 삭제합니다. 공지사항 삭제 후 되돌릴 수 없습니다.')) return

        await root.$store.dispatch('notice/deleteNotice', {
          id: Number(id),
        })

        alert('공지사항이 정상적으로 삭제되었습니다.')
        root.$router.push({ name: '공지사항' })
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    watch(
      () => props.notice,
      () => {
        state.input.title = props.notice.title
        state.input.content = props.notice.content
        state.input.image = props.notice.image
      },
    )

    return {
      ...toRefs(state),
      publishNotice,
      updateNotice,
      deleteNotice,
      day,
    }
  },
})
</script>
