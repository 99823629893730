<template>
  <v-container fluid>
    <Notice :notice="notice" @refresh="getOneNotice" />
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import Notice from '@/components/Notice.vue'

export default defineComponent({
  components: { Notice },

  setup(props, { root }) {
    const state = reactive({
      notice: null,
    })

    const getOneNotice = async () => {
      try {
        state.notice = await root.$store.dispatch('notice/getOneNotice', {
          id: Number(root.$route.params.uid),
        })
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    onBeforeMount(() => {
      getOneNotice()
    })

    return {
      ...toRefs(state),
      getOneNotice,
    }
  },
})
</script>
